import { cssScrollHelper, GALLERY_CONSTS } from 'pro-gallery';
import { Consts, window } from '@wix/photography-client-lib';

export default class FullscreenHelper {
  constructor(galleryWrapper, props, isStore) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStore = isStore;

    this.update = this.update.bind(this);
    this.fullscreenItemsProps = this.fullscreenItemsProps.bind(this);
    this.updateFullscreenCurrentItem = this.updateFullscreenCurrentItem.bind(
      this,
    );
    this.toggleBrowserFullscreen = this.toggleBrowserFullscreen.bind(this);
    this.getFullscreenIndex = this.getFullscreenIndex.bind(this);
    this.animatedOpenFullscreen = this.animatedOpenFullscreen.bind(this);
    this.animatedCloseFullscreen = this.animatedCloseFullscreen.bind(this);
  }

  update(props) {
    this.galleryWrapperProps = props;
    this.openFullscreenByProps(props);
  }

  openFullscreenByProps(props) {
    if (props.clickedIdx >= 0 && props.clickedIdx !== this.clickedIdx) {
      this.clickedIdx = props.clickedIdx;
      this.animatedOpenFullscreen({ idx: this.clickedIdx });
    }
  }

  fullscreenItemsProps = () => {
    let items, totalItemsCount;
    if (
      this.galleryWrapper.state.fullscreen.directFullscreenItem &&
      this.galleryWrapper.state.fullscreen.directFullscreenItem.itemId
    ) {
      items = [this.galleryWrapper.state.fullscreen.directFullscreenItem];
      totalItemsCount = 1;
      return {
        items,
        totalItemsCount,
      };
    } else {
      return this.galleryWrapper.itemsHelper.pgItemsProps();
    }
  };

  directFullscreenItemProps = () => {
    let items, totalItemsCount, fullscreenIdx;
    if (
      this.galleryWrapper.state.fullscreen.directFullscreenItem &&
      this.galleryWrapper.state.fullscreen.directFullscreenItem.itemId
    ) {
      items = [this.galleryWrapper.state.fullscreen.directFullscreenItem];
      totalItemsCount = 1;
      fullscreenIdx = 0;
      return {
        items,
        totalItemsCount,
        fullscreenIdx,
      };
    } else {
      return {};
    }
  };

  async updateFullscreenCurrentItem(galleryItem) {
    const fullscreen_url = await this.galleryWrapper.itemActionsHelper.useItemActions(
      'getShareUrl',
      galleryItem,
    );
    const page_url = await this.galleryWrapper.itemActionsHelper.useItemActions(
      'pageUrl',
    );
    const itemData = {
      item: {
        id: galleryItem.id,
        type: galleryItem.type, // image/video/text
        title: galleryItem.title, // image-title
        description: galleryItem.description, // image-description
        page_url,
        fullscreen_url,
        image: {
          url: galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.SEO,
          ),
          width: galleryItem.width,
          height: galleryItem.height,
        },
        video_url:
          galleryItem.videoUrl ||
          galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.VIDEO,
          ),
        thumbnail: {
          url: galleryItem.createUrl(
            GALLERY_CONSTS.urlSizes.RESIZED,
            GALLERY_CONSTS.urlTypes.LOW_RES,
          ),
          width: 250,
          height: 250,
        },
      },
    };
    if (typeof this.galleryWrapperProps.setMetaTags === 'function') {
      this.galleryWrapperProps.setMetaTags(itemData);
    }
    this.updateUrl(fullscreen_url);
  }

  getQueryParamsFromCurrentUrl() {
    const currentUrl = window && window.location.href;
    const queryParams = {};
    if (currentUrl.includes('?')) {
      currentUrl
        .split('?')[1]
        .split('&')
        .map((dim) => dim.split('='))
        .forEach((dim) => {
          queryParams[dim[0]] = isNaN(Number(dim[1]))
            ? String(dim[1])
            : Number(dim[1]);
        });
    }
    return queryParams;
  }

  canUpdateUrl() {
    const {isStoreGallery, isAlbumStore} = this.galleryWrapper.siteHelper;
    if (
      this.galleryWrapper.siteHelper.parseViewMode(
        this.galleryWrapperProps.viewMode,
      ) !== GALLERY_CONSTS.viewMode.SITE
    ) {
      return false;
    } else if (isStoreGallery === true && isAlbumStore === false ) {
      return false;
    }
    return true;
  }

  updateUrl(neededUrl) {
    if (!this.canUpdateUrl()) {
      return;
    }
    const queryParams = this.getQueryParamsFromCurrentUrl();
    // remove the last pgid, if there is one
    delete queryParams.pgid;
    // build queryParams to string
    const queryPairs = [];
    for (const [key, value] of Object.entries(queryParams)) {
      queryPairs.push(`${key}=${value}`);
    }
    const queryString = queryPairs.join('&');
    // build the new url
    const newUrl =
      neededUrl +
      (queryString.length > 0
        ? (neededUrl.includes('?') ? '&' : '?') + queryString
        : '');
    // pushState
    window.history.replaceState &&
      window.history.replaceState('Object', 'Title', newUrl);
  }

  toggleBrowserFullscreen() {
    if (
      !window.document.fullscreenElement &&
      !window.document.mozFullScreenElement &&
      !window.document.webkitFullscreenElement &&
      !window.document.msFullscreenElement
    ) {
      // current working methods
      if (window.document.documentElement.requestFullscreen) {
        window.document.documentElement.requestFullscreen();
      } else if (window.document.documentElement.msRequestFullscreen) {
        window.document.documentElement.msRequestFullscreen();
      } else if (window.document.documentElement.mozRequestFullScreen) {
        window.document.documentElement.mozRequestFullScreen();
      } else if (window.document.documentElement.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
      }
    }
  }

  getFullscreenIndex(itemProps) {
    const { totalItemsCount } = this.galleryWrapper.itemsHelper.pgItemsProps();
    if (itemProps.idx > 0 && totalItemsCount > 0) {
      return itemProps.idx % totalItemsCount;
    }
    return itemProps.idx;
  }

  setGalleryWrapperFullscreenState(fullscreen, callback) {
    this.galleryWrapper.setState({ fullscreen }, callback);
  }

  animatedOpenFullscreen(itemProps) {
    const expandAnimation = Consts.expandAnimations.FADE_IN; // styleParams.expandAnimation

    if (expandAnimation === Consts.expandAnimations.FADE_IN) {
      const fullscreen = Object.assign(
        {},
        this.galleryWrapper.state.fullscreen,
        {
          fullscreenAnimating: true,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
      );
      this.setGalleryWrapperFullscreenState(fullscreen, () => {
        const _fullscreen = Object.assign(
          {},
          this.galleryWrapper.state.fullscreen,
          {
            fullscreenAnimating: false,
          },
        );
        this.setGalleryWrapperFullscreenState(_fullscreen);
      });
    } else {
      const fullscreen = Object.assign(
        {},
        this.galleryWrapper.state.fullscreen,
        {
          fullscreenAnimating: false,
          clickedIdx: this.getFullscreenIndex(itemProps),
        },
      );
      this.setGalleryWrapperFullscreenState(fullscreen);
    }
  }

  animatedCloseFullscreen(itemIdx, animationDuration = 800) {
    // scroll to the item
    let y;
    try {
      if (itemIdx >= 0) {
        const itemDto = this.galleryWrapper.itemsHelper.pgItemsProps().items[
          itemIdx
        ];
        const item = { id: itemDto.itemId, idx: itemIdx };
        const itemDomId = cssScrollHelper.getSellectorDomId(item);
        const itemContainer = window.document.getElementById(itemDomId);
        const rect = itemContainer.getBoundingClientRect();
        const padding = (window.innerHeight - rect.height) / 2;
        y = window.scrollY + rect.y - padding;
        if (y >= 0) {
          this.galleryWrapperProps.scrollTo(0, y);
        }
        itemContainer.focus();
      }
    } catch (e) {
      console.warn('Could find last fullscreen item', itemIdx, e);
    }

    const fullscreen = Object.assign({}, this.galleryWrapper.state.fullscreen, {
      fullscreenAnimating: true,
    });

    this.setGalleryWrapperFullscreenState(fullscreen, async () => {
      setTimeout(() => {
        const _fullscreen = Object.assign(
          {},
          this.galleryWrapper.state.fullscreen,
          {
            directFullscreenItem: undefined,
            clickedIdx: -1,
          },
        );
        this.setGalleryWrapperFullscreenState(_fullscreen);
      }, animationDuration);
      if (typeof this.galleryWrapperProps.setMetaTags === 'function') {
        this.galleryWrapperProps.setMetaTags({});
      }
      this.updateUrl(
        await this.galleryWrapper.itemActionsHelper.useItemActions('pageUrl'),
      );
    });
  }
}
