import { utils } from '../../utils/webUtils';

export default class SyncEventHandler {
  constructor(galleryWrapper, galleryWrapperProps) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = galleryWrapperProps;
    this.reportedBiGalleryRendered = false;

    this.update = this.update.bind(this);
    this.eventHandler = this.eventHandler.bind(this);
    this.fullscreenEventHandler = this.fullscreenEventHandler.bind(this);
  }

  update(galleryWrapperProps) {
    this.galleryWrapperProps = galleryWrapperProps;
    if (this.asyncEventHandler) {
      this.asyncEventHandler.update(galleryWrapperProps);
    }
    if (
      this.galleryWrapperProps.appLoadStartedReported &&
      this.galleryChangeEventReported
    ) {
      this.onAppLoaded();
    }
  }

  onAppLoaded() {
    this.galleryWrapper.logHelper.onAppLoaded();
    this.reportedBiGalleryRenderedIfNeeded();
  }

  reportedBiGalleryRenderedIfNeeded() {
    // should report only once!
    if (!this.reportedBiGalleryRendered) {
      this.reportedBiGalleryRendered = true;
      // if (Math.random() < 0.01 || utils.shouldDebug('gallery_rendered')) {
      // use only 1 of 100 events (do not overflow the bi)
      // randomization removed temporarily for post-mortem reasons
      this.galleryWrapper.logHelper.reportBiEvent('gallery_rendered');
      // }
    }
  }

  eventHandler(eventName, eventData) {
    if (eventName === 'GALLERY_CHANGE') {
      this.onGalleryChangeEvent();
      this.galleryWrapper.siteHelper.handleNewGalleryStructure(eventData);
      return;
    }
    const whiteListedEvents = [
      'THUMBNAIL_CLICKED',
      'BUY_NOW_CLICKED',
      'DOWNLOAD_BUTTON_CLICKED',
      'ITEM_CLICKED',
      'LOVE_BUTTON_CLICKED',
      'TEXT_DOWNLOAD_BUTTON_CLICKED',
      'SHARE_BUTTON_CLICKED',
      'NEED_MORE_ITEMS',
      'CURRENT_ITEM_CHANGED',
      'ITEM_ACTION_TRIGGERED',
      'LOAD_MORE_CLICKED',
    ];
    const isEventHandled = whiteListedEvents.includes(eventName);
    if (isEventHandled) {
      if (!this.asyncEventHandler) {
        import(
          /* webpackChunkName: "AsyncEventHandler" */ './asyncEventHandler'
        ).then((module) => {
          this.asyncEventHandler = new module.default(
            this.galleryWrapper,
            this.galleryWrapperProps,
          );
          this.asyncEventHandler.handleEvent(eventName, eventData);
        });
      } else if (this.asyncEventHandler) {
        this.asyncEventHandler.handleEvent(eventName, eventData);
      }
    }
  }

  fullscreenEventHandler(eventName, eventData) {
    const whiteListedFullscreenEvents = [
      'UPDATE_CURRENT_ITEM',
      'CLOSE_FULLSCREEN',
      'NEED_MORE_ITEMS',
      'TOGGLE_BROWSER_FULLSCREEN',
      'NAVIGATE',
      'FULLSCREEN_LOADED',
      'SHARE_BUTTON_CLICKED',
      'TEXT_DOWNLOAD_BUTTON_CLICKED',
      'DOWNLOAD_BUTTON_CLICKED',
      'LOVE_BUTTON_CLICKED',
    ];
    const isEventHandled = whiteListedFullscreenEvents.includes(eventName);
    if (isEventHandled) {
      if (!this.asyncEventHandler) {
        import(
          /* webpackChunkName: "AsyncEventHandler" */ './asyncEventHandler'
        ).then((module) => {
          this.asyncEventHandler = new module.default(
            this.galleryWrapper,
            this.galleryWrapperProps,
          );
          this.asyncEventHandler.handleFullscreenEvent(eventName, eventData);
        });
      } else if (this.asyncEventHandler) {
        this.asyncEventHandler.handleFullscreenEvent(eventName, eventData);
      }
    }
  }

  onGalleryChangeEvent() {
    this.galleryChangeEventReported = true;
    if (this.galleryWrapperProps.appLoadStartedReported) {
      this.onAppLoaded();
    }
  }
}
