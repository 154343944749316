import { resizeMediaUrl } from '@wix/photography-client-lib';
import { PRO_GALLERY } from '../../../constants';
import CommonGalleryWrapper from '../../../common/CommonGalleryWrapper';
import { LeanGallery } from 'pro-gallery';

import ProFullscreenWrapper from '../FullscreenWrapper/FullscreenWrapper'; // TODO - remove this import when the SSR can accept different bundles

export default class LeanGalleryWrapper extends CommonGalleryWrapper {
  getGalleryComponent() {
    return LeanGallery;
  }

  isStoreGallery() {
    return false;
  }

  getFullscreenElementIfNeeded() {
    if (!this.canRenderFullscreen()) {
      return null;
    }
    if (typeof ProFullscreenWrapper !== 'undefined') {
      return ProFullscreenWrapper;
    } else if (!this.FullscreenWrapper) {
      import(
        /* webpackChunkName: "FullscreenWrapper" */ '../FullscreenWrapper/FullscreenWrapper'
      ).then((module) => {
        this.FullscreenWrapper = module.default;
        this.setState({ fullscreenWrapperLoaded: true });
      });
    }
    return this.FullscreenWrapper || null;
  }

  canRenderFullscreen() {
    return this.getFullscreenSelectedIndex() >= 0;
  }

  getSentryDSN() {
    return PRO_GALLERY.SENTRY_DSN;
  }

  getItemResizer() {
    return resizeMediaUrl;
  }
}
